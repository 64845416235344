/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import * as ReactDOM from "react-dom"
import React from "react"
import "./src/styles/font-face.scss"
import "./src/styles/styles.scss"

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    const root = container.parentElement

    if (root.id === "beam-frontend-page") {
      root.shadowRoot.appendChild(container)
    }

    ReactDOM.render(element, container, callback)
  }
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  const body = document.body
  const isWhiskeyFinder = location.pathname.includes("whiskey-finder")
  const isQuiz = location.pathname.includes("whiskey-finder/quiz")
  const isResults =
    location.pathname.includes("whiskey-finder/results")
    || location.pathname.includes("whiskey-finder/share")
  const isGuestAccountPage =
    location.pathname.includes("sign-in")
    || location.pathname.includes("sign-up")
    || location.pathname.includes("forgot-password")

  const isDebugTool = location.pathname.includes("debugging-tool")

  body.id = "beam-frontend-body"

  if (isQuiz) {
    body.classList.add("beam-frontend-no-header", "beam-frontend-no-footer")
  } else if (isDebugTool) {
    body.classList.add("beam-frontend-no-header", "beam-frontend-no-footer")
  } else if (isResults) {
    body.classList.remove("beam-frontend-no-footer")
    body.classList.add("beam-frontend-no-header")
  } else {
    body.classList.remove("beam-frontend-no-header", "beam-frontend-no-footer")
  }

  if (isGuestAccountPage) {
    body.classList.add("beam-frontend-guest-account-page")
  } else {
    body.classList.remove("beam-frontend-guest-account-page")
  }

  if (isWhiskeyFinder) {
    body.classList.add("beam-frontend-no-join-family")
  } else {
    body.classList.remove("beam-frontend-no-join-family")
  }

  let base = document.getElementsByTagName("base")
  if (base.length > 0 && base[0].href !== location.href) {
    base[0].href = location.origin + location.pathname
  } else if (base.length === 0) {
    document.head.innerHTML = document.head.innerHTML + "<base href='' />"
    base = document.getElementsByTagName("base")
    base[0].href = location.origin + location.pathname
  }
}
